import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import ShowroomSection from "../components/showroom-section";
import ServiceHero from "../components/service-hero";
import OpeningBanner from "../components/opening-banner";
import ThreeImageSection from "../components/three-image-section";
import Reviews from "../components/reviews";
import PriceListSection from "../components/price-list-section";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";

const ConservatoriesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: wpMediaItem(title: { eq: "tops-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "conservatories" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        conservatoriesPageFieldGroups {
          conservatoriesBannerSection {
            heading
            description
            cta1 {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          conservatoriesTextSection {
            heading
            description
          }
          conservatoriesTwoColumnSection {
            heading
            description
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          conservatoriesOpeningHoursSection {
            heading
          }
          conservatoriesPerfectionSection {
            heading
            description
            cta {
              title
              target
              url
            }
            imageSection {
              images {
                image {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        original {
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          conservatoriesFaqSection {
            heading
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          conservatoriesReviewsSection {
            heading
          }
          conservatoriesCtaSection1 {
            heading
            description
            cta {
              title
              target
              url
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, conservatoriesPageFieldGroups, slug },
  } = data;

  const {
    conservatoriesBannerSection,
    conservatoriesCtaSection1,
    conservatoriesPerfectionSection,
    conservatoriesFaqSection,
    conservatoriesReviewsSection,
    conservatoriesTextSection,
    conservatoriesTwoColumnSection,
    conservatoriesOpeningHoursSection,
  } = conservatoriesPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Conservatories",
        item: {
          url: `${siteUrl}/conservatories`,
          id: `${siteUrl}/conservatories`,
        },
      },
    ],
  };

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.topsgardenbuildings.com/conservatories`,
    image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.topsgardenbuildings.com/conservatories`,
    sku: `https://www.topsgardenbuildings.com/conservatories`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
    name: "Tops Garden Buildings",
    url: "https://www.topsgardenbuildings.com",
    brand: {
      "@type": "Organization",
      logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Tops Garden Buildings",
      url: "https://www.topsgardenbuildings.com",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: "125",
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.topsgardenbuildings.com",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.9",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Tops Garden Buildings",
        sameAs: "https://www.topsgardenbuildings.com",
      },
    },
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/conservatories`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {conservatoriesBannerSection &&
            !checkPropertiesForNull(conservatoriesBannerSection, [
              "heading",
            ]) && (
              <ServiceHero
                backgroundImage={
                  conservatoriesBannerSection.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                title={conservatoriesBannerSection?.heading}
                description={
                  <SafeHtmlParser
                    htmlContent={conservatoriesBannerSection?.description}
                  />
                }
                ctaButton={conservatoriesBannerSection?.cta1}
                image={conservatoriesBannerSection?.image.node}
              />
            )}
          {conservatoriesTextSection &&
            !checkPropertiesForNull(conservatoriesTextSection, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={4}>
                      <h2 className=" fs-1 mb-4 text-primary  ">
                        {conservatoriesTextSection?.heading}
                      </h2>
                    </Col>
                    <Col lg={8}>
                      <SafeHtmlParser
                        htmlContent={conservatoriesTextSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          {conservatoriesTwoColumnSection &&
            !checkPropertiesForNull(conservatoriesTwoColumnSection, [
              "heading",
            ]) && (
              <PriceListSection
                bgColour="bg-light-grey"
                mobileImageHeight="20rem"
                title={conservatoriesTwoColumnSection?.heading}
                text={
                  <SafeHtmlParser
                    htmlContent={conservatoriesTwoColumnSection?.description}
                  />
                }
                imageHeight="25rem"
                image={
                  conservatoriesTwoColumnSection.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={conservatoriesTwoColumnSection.image.node?.altText}
              />
            )}

          {conservatoriesPerfectionSection &&
            !checkPropertiesForNull(conservatoriesPerfectionSection, [
              "heading",
            ]) && (
              <ThreeImageSection
                btnText="VISIT US"
                title={conservatoriesPerfectionSection?.heading}
                text={
                  <div className="text-white">
                    <SafeHtmlParser htmlContent={conservatoriesPerfectionSection?.description} />
                  </div>
                }
                imageHeight="18rem"
                images={conservatoriesPerfectionSection.imageSection?.images}
                ctaButton={conservatoriesPerfectionSection.cta}
              />
            )}

          <Faq
            imageHeight="25rem"
            page="Conservatories"
            image={
              conservatoriesFaqSection?.image?.node?.localFile.childImageSharp.gatsbyImageData
            }
            imageAlt={conservatoriesFaqSection?.image?.node?.altText}
            heading={conservatoriesFaqSection?.heading}
          />
          <Reviews topPadding="pt-5 pt-md-0"  title={conservatoriesReviewsSection?.heading} />
        
          <OpeningBanner heading={conservatoriesOpeningHoursSection?.heading} />

          <ShowroomSection
            title={conservatoriesCtaSection1?.heading}
            description={<SafeHtmlParser htmlContent={conservatoriesCtaSection1?.description} />}
            ctaButton={conservatoriesCtaSection1?.cta}
          />
        </Layout>
      </div>
    </>
  );
};

export default ConservatoriesPage;
